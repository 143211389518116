import "../styles/About.css";
export default function About() {
  return (
    <div id="about">
      <h1 className="aboutMeHeader">ABOUT ME</h1>
      <div id="aboutMeDiv">
        <p className="aboutMe">
          At the beginning of my college career I was going to school for
          English and elementary education to pursue my interest of being an
          elementary teacher. My husband and I moved to California and in that
          state they have different schooling requirements to become a teacher
          so I decided to continue and finish my education at Arizona state
          university to get my bachelor degree in English.
        </p>
        <p className="aboutMe2">
          In 2021 my husband and I moved back to Michigan and that is when I
          decided to make a career change and venture into a new role. I took an
          intro to coding course at Grand Circus where we created a front-end
          webpage for a coffee shop.That is when I found out I really enjoyed
          front-end development. I enjoyed coding then instantly seeing the
          results displayed on the webpage. The design aspect is another reason
          I enjoy front-end development. After the intro to coding course, I
          decided to take the full stack javascript bootcamp, which I recently
          completed in July.
        </p>
      </div>
    </div>
  );
}
